import React from 'react';
import config from '@plone/volto/registry';
import { Container } from '@plone/components';
import { expandToBackendURL } from '@plone/volto/helpers/Url/Url';
import DownloadActions from '../DownloadActions/DownloadActions';

const formatParams = (data, page) => {
  const { settings } = config;
  let requestData = JSON.parse(JSON.stringify(data));

  if (data?.depth != null) {
    delete requestData.depth;
    requestData.query.forEach((q) => {
      if (q.i === 'path') {
        q.v += '::' + data.depth;
      }
    });
  }

  // fixes https://github.com/plone/volto/issues/2397
  if (requestData?.sort_order !== null) {
    if (typeof requestData.sort_order === 'boolean') {
      requestData.sort_order = requestData.sort_order
        ? 'descending'
        : 'ascending';
    }
  }

  const query = {
    ...requestData,
    ...(!requestData.b_size && {
      b_size: settings.defaultPageSize,
    }),
    ...(page && {
      b_start: requestData.b_size
        ? data.b_size * (page - 1)
        : settings.defaultPageSize * (page - 1),
    }),
    query: requestData?.query,
  };
  return `?query=${encodeURIComponent(JSON.stringify(query))}`;
};

const ExportRow = (props) => {
  const { url } = props;
  const api_url = expandToBackendURL(url);
  const endpoint = props.endpoint ? props.endpoint : 'formdata';
  const params = props.params ? formatParams(props.params) : '';

  const downloadActions = [
    {
      format: 'csv',
      href: `${api_url}/${endpoint}/csv${params}`,
    },
    {
      format: 'excel',
      href: `${api_url}/${endpoint}/excel${params}`,
    },
    {
      format: 'pdf',
      href: `${api_url}/${endpoint}/pdf${params}`,
    },
  ];
  return (
    downloadActions && (
      <Container className={'exportRow'}>
        <span className={'label'}>Baixar listagem como:</span>{' '}
        <DownloadActions actions={downloadActions} />
      </Container>
    )
  );
};

export default ExportRow;
