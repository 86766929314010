import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers/Extensions';
import SidebarPortal from '@plone/volto/components/manage/Sidebar/SidebarPortal';

import FiscaisBlockData from './Data';
import FiscaisBlockView from './View';

const FiscaisBlockEdit = (props) => {
  const { data, onChangeBlock, block, selected } = props;
  return (
    <>
      <FiscaisBlockView {...props} isEditMode />
      <SidebarPortal selected={selected}>
        <FiscaisBlockData
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </>
  );
};

export default withBlockExtensions(FiscaisBlockEdit);
