import React from 'react';
import PropTypes from 'prop-types';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import Image from '@plone/volto/components/theme/Image/Image';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const ProfilesTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  return (
    <>
      <div className="items profiles">
        {items.map((item) => {
          const ItemBodyTemplate = () => {
            const description = item?.partido
              ? item?.partido?.[0].title
              : item.description;
            return (
              <div className="card-container profile">
                <div className="item-image">
                  {item.image && item.image.length > 0 ? (
                    <Image
                      src={item.image[0].download}
                      alt=""
                      className="item-image"
                    />
                  ) : (
                    <img src={DefaultImageSVG} alt="" className="item-image" />
                  )}
                </div>
                <div className="item">
                  <div className="content">
                    {item?.cargo && (
                      <div className="headline">{item.cargo}</div>
                    )}
                    {item?.head_title && (
                      <div className="headline">{item.head_title}</div>
                    )}

                    <h2>{item?.title}</h2>
                    {!item.hide_description && <p>{description}</p>}
                  </div>
                </div>
              </div>
            );
          };
          return (
            <div className="listing-item" key={item['@id']}>
              <ConditionalLink item={item} condition={!isEditMode}>
                <ItemBodyTemplate item={item} />
              </ConditionalLink>
            </div>
          );
        })}
      </div>
      {link && <div className="footer">{link}</div>}
    </>
  );
};

ProfilesTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default ProfilesTemplate;
