/**
 * Pagamento view component.
 * @module components/Views/PagamentoView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@plone/components';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';
import LocalDate from '../LocalDate/LocalDate';
import CurrencyWidget from '../Widgets/CurrencyWidget';

const PagamentoView = (props) => {
  const { content } = props;
  const { fornecedor } = content;
  return (
    <Container
      narrow
      id="page-document"
      className="view-wrapper pagamento-view"
    >
      <h1 className="documentFirstHeading">{content.title}</h1>
      <span>{content.status.title}</span>
      <Table className={'details'}>
        <TableBody>
          <TableRow>
            <TableCell className={'label'}>Fonte de recursos</TableCell>
            <TableCell className={'value'}>
              {content.fonte_recursos ? content.fonte_recursos : ' - '}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Fornecedor</TableCell>
            <TableCell className={'value'}>
              {fornecedor ? (
                <UniversalLink item={fornecedor}>
                  {fornecedor.title}
                </UniversalLink>
              ) : (
                <span>{'-'}</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Valor</TableCell>
            <TableCell className={'value'}>
              <CurrencyWidget value={content.valor} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Data de envio</TableCell>
            <TableCell className={'value'}>
              {content.start ? (
                <LocalDate date={content.start} />
              ) : (
                <span>{'-'}</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Data atesto</TableCell>
            <TableCell className={'value'}>
              {content.data_atesto ? (
                <LocalDate date={content.data_atesto} />
              ) : (
                <span>{'-'}</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Data de liquidação</TableCell>
            <TableCell className={'value'}>
              {content.data_liquidacao ? (
                <LocalDate date={content.data_liquidacao} />
              ) : (
                <span>{'-'}</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Prazo máximo</TableCell>
            <TableCell className={'value'}>
              {content.data_maxima ? (
                <LocalDate date={content.data_maxima} />
              ) : (
                <span>{'-'}</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Pagamento</TableCell>
            <TableCell className={'value'}>
              {content.end ? (
                <LocalDate date={content.end} />
              ) : (
                <span>{'-'}</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Dias de suspensão</TableCell>
            <TableCell className={'value'}>
              {content.suspensao ? (
                <span>{content.suspensao}</span>
              ) : (
                <span>{'-'}</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Total de dias</TableCell>
            <TableCell className={'value'}>
              <span>{content.total_dias}</span>
            </TableCell>
          </TableRow>
        </TableBody>
        <Container className={'text'}>
          <p>{content.description}</p>
        </Container>
      </Table>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PagamentoView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default PagamentoView;
