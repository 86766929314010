import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVereadorData } from '../../../actions/Vereador/Vereador';
import Image from '@plone/volto/components/theme/Image/Image';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Container } from '@plone/components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import defaultProfile from './profile.png';

const ConditionalData = ({ value }) => {
  return <span>{value ? value : 'Não Informado'}</span>;
};

const placeholder = {
  '@id': '',
  title: 'Vereador',
  telefone_gabinete: '(81)5555-5555',
  email: 'info@recife.pe.leg.br',
  filiacoes: [{ title: 'Partido' }],
};

const VereadorCard = ({ vereador }) => {
  const { title, image, telefone_gabinete, email, filiacoes } = vereador;
  const partido = filiacoes ? filiacoes[0]?.title : 'Sem Partido';
  const img_src = image ? image[0].download : null;
  return (
    <>
      <Container className={'image'}>
        {img_src ? (
          <Image
            src={img_src}
            alt={`Foto de ${title}`}
            className={'portrait'}
          />
        ) : (
          <Image
            src={defaultProfile}
            alt={'Foto do vereador'}
            className={'portrait'}
          />
        )}
      </Container>
      <Container className={'content'}>
        <Container className={'nome'}>
          <span>{title}</span>
        </Container>
        <Container className={'meta'}>
          <ConditionalData value={partido} />
        </Container>
        <Container className={'dados'}>
          <ul>
            <li>
              <span className={'label'}>Telefone</span>:{' '}
              <ConditionalData value={telefone_gabinete} />
            </li>
            <li>
              <span className={'label'}>E-mail</span>:{' '}
              <ConditionalData value={email} />
            </li>
            {vereador['@id'] && (
              <li>
                <UniversalLink item={vereador}>Página de {title}</UniversalLink>
              </li>
            )}
          </ul>
        </Container>
      </Container>
    </>
  );
};

const VereadorView = (props) => {
  const { className, cod_vereador } = props;
  const dispatch = useDispatch();
  const vereador = useSelector(
    (state) => state.vereadorData?.subrequests?.[cod_vereador]?.data || {},
  );
  const loading = useSelector(
    (state) =>
      state.vereadorData?.subrequests?.[cod_vereador]?.loading || false,
  );

  useEffect(() => {
    if (cod_vereador !== undefined) {
      dispatch(getVereadorData(cod_vereador));
    }
  }, [dispatch, cod_vereador]);
  return (
    <Container className={`block vereador ${className}`}>
      <div className={'vereadorWrapper'}>
        <Container className={'card'}>
          <Dimmer active={loading ? true : false} inverted>
            <Loader indeterminate size="small">
              <FormattedMessage id="loading" defaultMessage="Loading" />
            </Loader>
          </Dimmer>
          <VereadorCard vereador={vereador?.title ? vereador : placeholder} />
        </Container>
      </div>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
VereadorView.propTypes = {
  Vereador: PropTypes.string,
};

export default VereadorView;
