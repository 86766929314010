/**
 * Comissao view component.
 * @module components/Views/ComissaoView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TabPane, Tab } from 'semantic-ui-react';
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
} from 'semantic-ui-react';
import { Container } from '@plone/components';
import Image from '@plone/volto/components/theme/Image/Image';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { DownloadLink } from '../../DownloadActions/DownloadActions';
import LocalDate from '../../LocalDate/LocalDate';

const ConditionalData = ({ value }) => {
  return <span>{value ? value : 'Não Informado'}</span>;
};

const DownloadFiles = ({ files }) => {
  const hasFiles = files && files.length > 0;
  const file = hasFiles ? files[0] : null;
  return (
    <div className={'download files'}>
      {file && (
        <DownloadLink format="pdf" href={file.download} title={'Download'} />
      )}
    </div>
  );
};

const Participantes = ({ items }) => {
  return (
    <Table celled>
      <TableHeader>
        <TableRow>
          <TableHeaderCell></TableHeaderCell>
          <TableHeaderCell>Cargo</TableHeaderCell>
          <TableHeaderCell>Nome</TableHeaderCell>
        </TableRow>
      </TableHeader>

      <TableBody>
        {items &&
          items.map((item, idx) => (
            <TableRow key={idx} className={'participante'}>
              <TableCell className={'portraitCell'}>
                {item?.image && item.image?.length > 0 && (
                  <div className={'portraitWrapper'}>
                    <Image
                      src={item.image[0].download}
                      className={'portrait'}
                    />
                  </div>
                )}
              </TableCell>
              <TableCell className={'info cargo'}>{item.cargo}</TableCell>
              <TableCell className={'info title'}>
                <UniversalLink item={item}>{item.title}</UniversalLink>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const Periodos = ({ items }) => {
  return (
    <Table celled>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>#</TableHeaderCell>
          <TableHeaderCell>Início</TableHeaderCell>
          <TableHeaderCell>Fim</TableHeaderCell>
        </TableRow>
      </TableHeader>

      <TableBody>
        {items &&
          items.map((item, idx) => (
            <TableRow key={idx}>
              <TableCell>{item.id}</TableCell>
              <TableCell>
                <LocalDate date={item.start} />
              </TableCell>
              <TableCell>
                <LocalDate date={item.end} />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const Reunioes = ({ reunioes }) => {
  return (
    <Table celled>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Título</TableHeaderCell>
          <TableHeaderCell>Data</TableHeaderCell>
          <TableHeaderCell>Início</TableHeaderCell>
          <TableHeaderCell>Encerramento</TableHeaderCell>
          <TableHeaderCell>Ata</TableHeaderCell>
          <TableHeaderCell>Pauta</TableHeaderCell>
        </TableRow>
      </TableHeader>

      <TableBody>
        {reunioes?.items &&
          reunioes?.items?.map((item, idx) => (
            <TableRow key={idx} positive={item.filiacao_atual}>
              <TableCell>{item.title}</TableCell>
              <TableCell>
                <LocalDate date={item.data} />
              </TableCell>
              <TableCell>{item.hora_abertura}</TableCell>
              <TableCell>
                {item.hora_encerramento ? item.hora_encerramento : '-'}
              </TableCell>
              <TableCell>
                <DownloadFiles files={item.arquivo_ata} />
              </TableCell>
              <TableCell>
                <DownloadFiles files={item.arquivo_pauta} />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

/**
 * Comissao view component class.
 * @function ComissaoView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ComissaoView = (props) => {
  const { content } = props;
  const { title, description, tipo, items, periodos, reunioes } = content;

  const panes = [
    {
      menuItem: 'Participantes',
      render: () => (
        <TabPane>
          <Participantes items={items} />
        </TabPane>
      ),
    },
    {
      menuItem: 'Períodos',
      render: () => (
        <TabPane>
          <Periodos items={periodos} />
        </TabPane>
      ),
    },
    {
      menuItem: 'Reuniões',
      render: () => (
        <TabPane>
          <Reunioes reunioes={reunioes} />
        </TabPane>
      ),
    },
  ];

  return (
    <Container narrow id="page-document" className="view-wrapper comissao-view">
      <h1 className="documentFirstHeading">{title}</h1>
      <p className={'description'}>
        <span className={'label'}>
          Comissão <ConditionalData value={tipo} />
        </span>{' '}
        {' - '} {description}
      </p>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ComissaoView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default ComissaoView;
