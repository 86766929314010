/**
 * ContratacaoView view component.
 * @module components/View/Licitacao/ContratacaoView
 */
import React from 'react';
import DataTable from 'react-data-table-component';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';
import Estado from '../../Estado/Estado';
import { processColumns } from '../../../helpers/columns';
import { trimText } from '../../../helpers/trim';
import LocalDate from '../../LocalDate/LocalDate';
import CurrencyWidget from '../../Widgets/CurrencyWidget';

const paginationPerPage = 20;

const paginationComponentOptions = {
  rowsPerPageText: 'Linhas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: false,
  selectAllRowsItemText: 'Todos',
};

const Colaborador = ({ item }) => {
  const hasLink = item?.['@id'] ? true : false;
  return hasLink ? (
    <UniversalLink item={item} className={'colaborador'}>
      {item.title}
    </UniversalLink>
  ) : (
    <span className={'colaborador'}>{item.title}</span>
  );
};

const items_columns = processColumns({
  main: [
    {
      id: 'descricao',
      style: 'text wrap',
      title: 'Descrição',
    },
    {
      id: 'quantidade',
      title: 'Quantidade',
      right: true,
    },
    {
      id: 'unidade_medida',
      title: 'Unidade',
      center: true,
    },
    {
      id: 'valor_unitario',
      title: 'Valor Un.',
      right: true,
    },
    {
      id: 'valor_total',
      title: 'Total',
      right: true,
    },
  ],
});

const document_columns = [
  {
    id: 'title',
    name: 'Documento',
    selector: (row) => row.title,
    format: (row, index) => (
      <span className={`documento link`}>
        <UniversalLink item={row}>{row.title}</UniversalLink>
      </span>
    ),
  },
  {
    id: 'description',
    name: 'Sumário',
    selector: (row) => row.description,
    format: (row, index) => (
      <span className={`documento sumario text wrap`}>
        {row.description ? trimText(row.description) : '-'}
      </span>
    ),
  },
];

const DadosBasicos = ({ content }) => {
  return (
    <Table className={'details'}>
      <TableBody>
        <TableRow>
          <TableCell className={'label'}>Instrumento</TableCell>
          <TableCell className={'value'}>
            {content.tipo_instrumento ? content.tipo_instrumento.title : '-'}
          </TableCell>
          <TableCell className={'label'}>Modo de disputa</TableCell>
          <TableCell className={'value'}>
            {content.modo_disputa ? content.modo_disputa.title : '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Modalidade</TableCell>
          <TableCell className={'value'}>
            {content.modalidade ? content.modalidade.title : '-'}
          </TableCell>
          <TableCell className={'label'}>Número</TableCell>
          <TableCell className={'value'}>
            {content.numero ? content.numero : '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Amparo legal</TableCell>
          <TableCell className={'value'}>
            {content.amparo_legal ? content.amparo_legal.title : '-'}
          </TableCell>
          <TableCell className={'label'}>
            Sistema de Registro de Preços
          </TableCell>
          <TableCell className={'value'}>
            {content.srp ? 'Sim' : 'Não'}
          </TableCell>
        </TableRow>
        {content.start && (
          <TableRow>
            <TableCell className={'label'}>Início recebimento</TableCell>
            <TableCell className={'value'}>
              <LocalDate date={content.start} />
            </TableCell>
            <TableCell className={'label'}>Término recebimento</TableCell>
            <TableCell className={'value'}>
              <LocalDate date={content.end} />
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell className={'label'}>Valor estimado</TableCell>
          <TableCell className={'value'}>
            <CurrencyWidget value={content.valor_estimado} />
          </TableCell>
          <TableCell className={'label'}>Valor homologado</TableCell>
          <TableCell className={'value'}>
            <CurrencyWidget value={content.valor_homologado} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const DadosContrato = ({ content }) => {
  const total_fornecedores = content.lista_fornecedores?.length || 0;
  const label_fornecedores = total_fornecedores > 1 ? 'Empresas' : 'Empresa';
  return (
    <Table className={'details'}>
      <TableBody>
        {content.start_contrato && (
          <TableRow>
            <TableCell className={'label'} width={3}>
              Início contrato
            </TableCell>
            <TableCell className={'value'} width={9}>
              <LocalDate date={content.start_contrato} />
            </TableCell>
          </TableRow>
        )}
        {content.end_contrato && (
          <TableRow>
            <TableCell className={'label'} width={3}>
              Término contrato
            </TableCell>
            <TableCell className={'value'} width={9}>
              <LocalDate date={content.end_contrato} />
            </TableCell>
          </TableRow>
        )}
        {total_fornecedores && (
          <TableRow>
            <TableCell className={'label'}>{label_fornecedores}</TableCell>
            <TableCell className={'value'} width={9}>
              {content.lista_fornecedores.map((item) => {
                return (
                  <UniversalLink key={item['@id']} item={item}>
                    {item.title}
                  </UniversalLink>
                );
              })}
            </TableCell>
          </TableRow>
        )}
        {content.fiscais && (
          <TableRow>
            <TableCell className={'label'}>Fiscais do contrato</TableCell>
            <TableCell className={'value'} width={9}>
              {content.lista_fiscais.map((item) => {
                return <Colaborador key={item['@id']} item={item} />;
              })}
            </TableCell>
          </TableRow>
        )}
        {content.gestores && (
          <TableRow>
            <TableCell className={'label'}>Gestores do contrato</TableCell>
            <TableCell className={'value'} width={9}>
              {content.lista_gestores.map((item) => {
                return <Colaborador key={item['@id']} item={item} />;
              })}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const Documentos = ({ items, items_total }) => {
  const options = { striped: true };
  if (items_total > paginationPerPage) {
    options['pagination'] = true;
    options['paginationComponentOptions'] = paginationComponentOptions;
    options['paginationPerPage'] = paginationPerPage;
    options['paginationRowsPerPageOptions'] = [10, 15, 20, 25, 30, 50, 100];
  }

  return (
    items_total > 0 && (
      <>
        <h2 className={'headline'}>Documentos</h2>
        <DataTable columns={document_columns} data={items} {...options} />
      </>
    )
  );
};

/**
 * ContratacaoView view component class.
 * @function ContratacaoView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ContratacaoView = ({ content }) => {
  const { title, description } = content;
  return (
    <div
      id="page-document"
      className="ui container view-wrapper contratacao-view"
    >
      <div className={'header'}>
        <h1 className="documentFirstHeading">{title}</h1>
        <Estado review_state={content.review_state} />
      </div>
      <h2 className={'headline'}>Objeto</h2>
      <p>{description}</p>
      <h2 className={'headline'}>Detalhes</h2>
      <DadosBasicos content={content} />
      {content.itens_compra && content.itens_compra.length > 0 && (
        <>
          <h2 className={'headline'}>Ítens da Contratação</h2>
          <DataTable
            columns={items_columns.main}
            data={content.itens_compra}
            striped
          />
        </>
      )}
      {content.review_state === 'concluido' && (
        <>
          <h2 className={'headline'}>Contrato</h2>
          <DadosContrato content={content} />
        </>
      )}
      <Documentos items={content.items} items_total={content.items_total} />
    </div>
  );
};

export default ContratacaoView;
