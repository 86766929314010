import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@plone/components';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { setAcessibilidade } from '../../actions/Acessibilidade/Acessibilidade';
import BodyClass from '@plone/volto/helpers/BodyClass/BodyClass';
import wheelchairSVG from '../../icons/wheelchair.svg';
import contTrueSVG from '../../icons/contraste-true.svg';
import contFalseSVG from '../../icons/contraste-false.svg';

const ContrasteIcons = {
  h: contTrueSVG,
  d: contFalseSVG,
};

const LinkTexto = () => {
  return (
    <Container className={'option link texto'}>
      <UniversalLink href={'/acessibilidade'}>
        <Icon
          name={wheelchairSVG}
          size={'16px'}
          title={'Link Acessibilidade'}
          className={'icon'}
        />
        <span className={'label'}>Acessibilidade</span>
      </UniversalLink>
    </Container>
  );
};

const SiteMap = () => {
  return (
    <Container className={'option link texto'}>
      <UniversalLink href={'/sitemap'}>
        <span className={'label'}>Mapa do site</span>
      </UniversalLink>
    </Container>
  );
};

const Contraste = ({ atual, onClick }) => {
  const iconName = ContrasteIcons[atual] || contFalseSVG;
  const value = atual === 'd' ? 'h' : 'd';
  const handleClick = (e) => {
    let target = e.target;
    if (target.nodeName === 'svg') {
      target = target.parentNode;
    }
    const { value } = target;
    onClick(value);
  };
  return (
    <Container className={'option contraste'}>
      <button value={value} onClick={handleClick}>
        <Icon
          name={iconName}
          size={'16px'}
          title={'Alto Contraste'}
          className={'icon'}
        />
      </button>
    </Container>
  );
};

const Fontes = ({ atual, onClick }) => {
  const fontes = [
    ['l', 'A+'],
    ['m', 'A'],
    ['s', 'A-'],
  ];
  const handleClick = (e) => {
    const { value } = e.target;
    onClick(value);
  };
  return (
    <Container className={'option fonte'}>
      {fontes.map((item, idx) => {
        const value = item[0];
        const label = item[1];
        const options = {
          value: value,
          onClick: handleClick,
        };
        return (
          <button key={idx} {...options}>
            {label}
          </button>
        );
      })}
    </Container>
  );
};

const BarraAcessibilidade = () => {
  const acessibilidade = useSelector((state) => state.acessibilidade);
  const dispatch = useDispatch();
  const handleContrasteClick = (value) => {
    if (value === undefined) {
      value = 'd';
    }
    dispatch(setAcessibilidade(value, acessibilidade.fonte));
  };

  const handleFonteClick = (value) => {
    if (value === undefined) {
      value = 'm';
    }
    dispatch(setAcessibilidade(acessibilidade.contraste, value));
  };
  return (
    <Container className={'barra-wrapper'}>
      <BodyClass
        className={`acc-cont-${acessibilidade.contraste} acc-font-${acessibilidade.fonte}`}
      />
      <Container layout className={'barra'}>
        <LinkTexto />
        <Contraste
          atual={acessibilidade.contraste}
          onClick={handleContrasteClick}
        />
        <Fontes atual={acessibilidade.fonte} onClick={handleFonteClick} />
        <SiteMap />
      </Container>
    </Container>
  );
};

export default BarraAcessibilidade;
