import React from 'react';
import PropTypes from 'prop-types';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const SimpleGridTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  return (
    <>
      <div className="items lateral">
        {items.map((item) => {
          const ItemBodyTemplate = () => {
            return (
              <div className="card-container">
                <div className="item-image">
                  {item.image_field !== '' && (
                    <img
                      src={`${item['@id']}/@@images/preview_image/preview`}
                      alt={item.title}
                      className="item-image"
                    />
                  )}
                </div>
                <div className="item">
                  <div className="content">
                    {item?.head_title && (
                      <div className="headline">{item.head_title}</div>
                    )}

                    <h2>{item?.title}</h2>
                    {!item.hide_description && <p>{item?.description}</p>}
                  </div>
                </div>
              </div>
            );
          };
          return (
            <div className="listing-item" key={item['@id']}>
              <ConditionalLink item={item} condition={!isEditMode}>
                <ItemBodyTemplate item={item} />
              </ConditionalLink>
            </div>
          );
        })}
      </div>
      {link && <div className="footer">{link}</div>}
    </>
  );
};

SimpleGridTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default SimpleGridTemplate;
