/**
 * ExternalDataForm view component.
 * @module components/components/ExternalDataForm/ExternalDataForm
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getLocalFormData } from '../../actions/LocalForm/LocalForm';
import { useDispatch, useSelector } from 'react-redux';
import Form from '@rjsf/semantic-ui';
import validator from '@rjsf/validator-ajv8';
import { expandToBackendURL } from '@plone/volto/helpers/Url/Url';
import { Dimmer, Loader } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const ExternalDataForm = (props) => {
  const {
    title,
    location,
    content,
    display_form,
    setLocalFormData,
    view_config,
    endpoint,
    displayCancel,
  } = props;
  const { UID } = content;
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState();

  const dispatch = useDispatch();

  const schema = view_config ? view_config?.form : content.view_config?.form;
  const formSchema = schema?.formSchema;
  const uiSchema = schema?.uiSchema;
  const initialData = schema?.initialData;

  // reset data
  useEffect(() => {
    setLocalFormData({});
  }, [setLocalFormData]);

  const localFormData = useSelector(
    (state) => state.localFormData?.subrequests?.[UID]?.data,
  );

  const loaded = useSelector(
    (state) => state.localFormData?.subrequests?.[UID]?.loaded,
  );

  const loading = useSelector(
    (state) => state.localFormData?.subrequests?.[UID]?.loading,
  );

  useEffect(() => {
    setFormData(initialData);
  }, [initialData, setFormData]);

  useEffect(() => {
    if (submitted && loaded && localFormData) {
      setSubmitted(false);
      setLocalFormData(localFormData);
    }
  }, [submitted, loaded, localFormData, setLocalFormData]);

  const onhandleChange = (data) => {
    setFormData(data);
  };

  const onhandleCancel = () => {
    setFormData({});
  };

  const onhandleSubmit = () => {
    setSubmitted(true);
    dispatch(
      getLocalFormData(
        expandToBackendURL(location.pathname),
        formData,
        UID,
        endpoint,
      ),
    );
  };

  useEffect(() => {
    if (display_form === false) {
      setSubmitted(true);
      dispatch(
        getLocalFormData(
          expandToBackendURL(location.pathname),
          {},
          UID,
          endpoint,
        ),
      );
    }
  }, [
    display_form,
    setSubmitted,
    UID,
    formData,
    endpoint,
    dispatch,
    location.pathname,
  ]);

  return (
    <div className={`externalDataForm`}>
      <div>
        <Dimmer active={loading} inverted>
          <Loader indeterminate size="small">
            <FormattedMessage id="loading" defaultMessage="Loading" />
          </Loader>
        </Dimmer>
      </div>
      {display_form && (
        <div className={'formWrapper'}>
          {title && <h2 className="headline">{title}</h2>}
          <Form
            schema={formSchema}
            uiSchema={uiSchema}
            formData={formData}
            validator={validator}
            onChange={(e) => onhandleChange(e.formData)}
            onSubmit={onhandleSubmit}
          >
            <div className={'actions'}>
              <button type="submit" className={'ui primary button'}>
                {uiSchema?.['ui:submitButtonOptions']?.submitText
                  ? uiSchema['ui:submitButtonOptions'].submitText
                  : 'Pesquisar'}
              </button>
              {displayCancel && (
                <button
                  type="reset"
                  className={'ui danger button'}
                  onClick={onhandleCancel}
                >
                  {uiSchema?.['ui:cancelButtonOptions']?.cancelText
                    ? uiSchema['ui:cancelButtonOptions'].cancelText
                    : 'Limpar'}
                </button>
              )}
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ExternalDataForm.propTypes = {
  title: PropTypes.string,
  display_form: PropTypes.bool.isRequired,
};
ExternalDataForm.defaultProps = {
  display_form: true,
};

export default ExternalDataForm;
