import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers/Extensions';
import FiscaisView from './DefaultView';

const FiscaisBlockView = (props) => {
  const { data, content, isEditMode, className, location } = props;
  return (
    <FiscaisView
      {...data}
      location={location}
      content={content}
      isEditMode={isEditMode}
      className={className}
    />
  );
};

export default withBlockExtensions(FiscaisBlockView);
