import { Container } from '@plone/components';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import PDFBlockView from '@eeacms/volto-pdf-block/components/manage/PDFViewer/BlockView';
import FileType from '@kitconcept/volto-light-theme/helpers/Filetype';

const FileViewer = ({ file, header }) => {
  const contentType = file['content-type'];
  const downloadUrl = file?.download && flattenToAppURL(file.download);
  return (
    <Container narrow className="file-wrapper">
      {header && <h2 className="headline">{header}</h2>}
      {contentType === 'application/pdf' && (
        <PDFBlockView data={{ url: downloadUrl }} />
      )}
      {downloadUrl && (
        <p>
          <a href={downloadUrl}>{file.filename}</a>{' '}
          <span>
            ({FileType(file['content-type'])}/{' '}
            {file?.size < 1000000
              ? Math.round(file.size / 1000)
              : Math.round(file.size / 1000000)}
            {file?.size < 1000000 ? 'KB' : 'MB'})
          </span>
        </p>
      )}
    </Container>
  );
};

export default FileViewer;
