/**
 * AreaView view component.
 * @module components/View/AreaView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { getBaseUrl } from '@plone/volto/helpers/Url/Url';
import { hasBlocksData } from '@plone/volto/helpers/Blocks/Blocks';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';

/**
 * AreaView view component class.
 * @function AreaView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const AreaView = (props) => {
  const { content, location } = props;
  const { title, description } = content;
  const path = getBaseUrl(location?.pathname || '');

  return (
    <div id="page-document" className="ui container view-wrapper area-view">
      {hasBlocksData(content) ? (
        <RenderBlocks {...props} path={path} />
      ) : (
        <>
          <h1 className="documentFirstHeading">{title}</h1>
          {description && <p className="documentDescription">{description}</p>}
        </>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
AreaView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    email: PropTypes.string,
    ramal: PropTypes.string,
    tipo_email: PropTypes.shape({
      title: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        '@id': PropTypes.string.isRequired,
      }),
    ),
    pessoas: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        '@id': PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default AreaView;
