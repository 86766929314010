/**
 * Diaria view component.
 * @module components/Views/DiariaView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@plone/components';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import LocalDate from '../LocalDate/LocalDate';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';

/**
 * Diaria view component class.
 * @function Diaria
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const DiariaView = (props) => {
  const { content } = props;
  const { title } = content;

  return (
    <Container narrow id="page-document" className="view-wrapper diaria-view">
      <h1 className="documentFirstHeading">{title}</h1>
      <Container>
        <Table className={'details'}>
          <TableBody>
            <TableRow>
              <TableCell className={'label'}>Beneficiário</TableCell>
              <TableCell className={'value'}>
                <UniversalLink item={content.beneficiario}>
                  {content.beneficiario_nome}
                </UniversalLink>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={'label'}>Cargo</TableCell>
              <TableCell className={'value'}>
                {content.beneficiario_cargo}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={'label'}>Número de Diárias</TableCell>
              <TableCell className={'value'}>{content.diarias}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={'label'}>Início</TableCell>
              <TableCell className={'value'}>
                <LocalDate date={content.start} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={'label'}>Término</TableCell>
              <TableCell className={'value'}>
                <LocalDate date={content.end} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={'label'}>Motivo</TableCell>
              <TableCell className={'value'}>{content.motivo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={'label'}>Local</TableCell>
              <TableCell className={'value'}>{content.local}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={'label'}>Valor Unitário</TableCell>
              <TableCell className={'value'}>{content.unitario}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={'label'}>Valor Total</TableCell>
              <TableCell className={'value'}>{content.total}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={'label'}>Pagamento</TableCell>
              <TableCell className={'value'}>
                {content.data_pagamento && (
                  <LocalDate date={content.data_pagamento} />
                )}
              </TableCell>
            </TableRow>
            {content.nota_liquidacao && (
              <TableRow>
                <TableCell className={'label'}>Nota de liquidação</TableCell>
                <TableCell className={'value'}>
                  {content.nota_liquidacao}
                </TableCell>
              </TableRow>
            )}
            {content.observacao && (
              <TableRow>
                <TableCell className={'label'}>Observações</TableCell>
                <TableCell className={'value'}>{content.observacao}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Container>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DiariaView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default DiariaView;
