/**
 * InformacaoCeap view component.
 * @module components/Views/InformacaoCeapView
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import { getBaseUrl } from '@plone/volto/helpers/Url/Url';
import { hasBlocksData } from '@plone/volto/helpers/Blocks/Blocks';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import ExternalDataForm from '../ExternalDataForm/ExternalDataForm';
import ExternalDataResults from '../ExternalDataResults/ExternalDataResults';
import NextPrevious from '../NextPrevious/NextPrevious';
import { processColumns } from '../../helpers/columns';
import logoCMR from '../../components/Logo/Logo-Black.png';

const InformacaoCeapView = (props) => {
  const { content, location } = props;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const allowedTypes = ['InformacaoCeap', 'Document'];
  const path = getBaseUrl(location?.pathname || '');
  const [showForm, setShowForm] = useState(true);
  const [localData, setLocalData] = useState();
  const { title, description, data, view_config, display_form } = content;
  useEffect(() => {
    setLocalData(data);
  }, [data, setLocalData]);

  const displayForm = () => {
    setShowForm(true);
    setLocalData({});
  };

  const setLocalFormData = (data) => {
    if (data?.data?.items) {
      setLocalData(data);
      setShowForm(false);
    }
  };
  const pagination = view_config?.display?.pagination;
  const paginationPerPage = pagination?.paginationPerPage;
  const columns = processColumns(view_config?.display?.columns);
  const expandRows = view_config?.display?.expandRows;
  const expandRowsType = view_config?.display?.expandRowsType;
  const metadata = localData?.metadata;
  return (
    <Container
      id="page-document"
      className="view-wrapper informacaoemprel-view"
    >
      {hasBlocksData(content) ? (
        <RenderBlocks {...props} path={path} />
      ) : (
        <>
          <h1 className="documentFirstHeading">{title}</h1>
          {description && <p className="documentDescription">{description}</p>}
        </>
      )}
      {!showForm && metadata && (
        <ExternalDataResults
          data={localData.data}
          metadata={metadata}
          columns={columns}
          showForm={displayForm}
          display_form={display_form}
          expandRows={expandRows}
          expandRowsType={expandRowsType}
          paginationPerPage={paginationPerPage}
          signature={{
            link: '"https://www.recife.leg.br/',
            image: logoCMR,
            alt: 'Logo Câmara Municipal do Recife',
          }}
        />
      )}
      {showForm && (
        <ExternalDataForm
          {...props}
          title={'Filtro'}
          setLocalFormData={setLocalFormData}
        />
      )}
      <NextPrevious content={content} allowedTypes={allowedTypes} />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
InformacaoCeapView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default InformacaoCeapView;
