import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers/Extensions';
import DiariasView from './DefaultView';

const DiariasBlockView = (props) => {
  const { data, content, isEditMode, className, location } = props;
  return (
    <DiariasView
      {...data}
      location={location}
      content={content}
      isEditMode={isEditMode}
      className={className}
    />
  );
};

export default withBlockExtensions(DiariasBlockView);
