import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers/Extensions';
import ContatoView from './DefaultView';

const ContatoBlockView = (props) => {
  const { data, isEditMode, className } = props;
  return (
    <ContatoView {...data} isEditMode={isEditMode} className={className} />
  );
};

export default withBlockExtensions(ContatoBlockView);
