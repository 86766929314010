/**
 * Norma view component.
 * @module components/Views/NormaView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@plone/components';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';
import { DownloadLink } from '../../DownloadActions/DownloadActions';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

const DownloadFiles = ({ files }) => {
  const hasFiles = files && files.length > 0;
  const file = hasFiles ? files[0] : null;
  return (
    <div className={'download files'}>
      {file && (
        <DownloadLink format="pdf" href={file.download} title={'Download'} />
      )}
    </div>
  );
};

const Details = ({ content }) => {
  return (
    <Container className={'details'}>
      <Table className={'details'}>
        <TableBody>
          <TableRow>
            <TableCell className={'label'}>Data de Apresentação</TableCell>
            <TableCell className={'value'}>{content.data_norma}</TableCell>
          </TableRow>
          {content.data_publicacao && (
            <TableRow>
              <TableCell className={'label'}>Data de Publicação</TableCell>
              <TableCell className={'value'}>
                {content.data_publicacao}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell className={'label'}>Autoria</TableCell>
            <TableCell className={'value'}>
              {content.materia && content.materia.length > 0
                ? content.materia[0].autoria[0].title
                : '-'}
            </TableCell>
          </TableRow>
          {content.file && (
            <TableRow>
              <TableCell className={'label'}>Texto Integral</TableCell>
              <TableCell className={'value'}>
                <DownloadFiles files={content.file} />
              </TableCell>
            </TableRow>
          )}
          {content.texto_compilado && (
            <TableRow>
              <TableCell className={'label'}>Texto Compilado</TableCell>
              <TableCell className={'value'}>
                <DownloadFiles files={content.texto_compilado} />
              </TableCell>
            </TableRow>
          )}
          {content.anexos && content.anexos.length > 0 && (
            <TableRow>
              <TableCell className={'label'}>Anexos</TableCell>
              <TableCell className={'value'}>
                {content.anexos.map((file, idx) => (
                  <DownloadFiles key={idx} files={[file]} />
                ))}
              </TableCell>
            </TableRow>
          )}
          {content.remoteUrl && (
            <TableRow>
              <TableCell className={'label'}>Mais Informações</TableCell>
              <TableCell className={'value'}>
                <UniversalLink href={content.remoteUrl}>
                  e-Processos
                </UniversalLink>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Container>
  );
};

/**
 * Norma view component class.
 * @function NormaView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const NormaView = (props) => {
  const { content } = props;
  const { title, description } = content;

  return (
    <Container narrow id="page-document" className="view-wrapper norma-view">
      <h1 className="documentFirstHeading">{title}</h1>
      <p className={'description'}>{description}</p>
      <Details content={content} />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NormaView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default NormaView;
