import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { Card } from 'semantic-ui-react';
import { Container } from '@plone/components';
import personSVG from '@plone/volto/icons/user.svg';

const Header = ({ title }) => {
  return <h2 className={'headline'}>{title}</h2>;
};

const ColaboradoresView = (props) => {
  const { className, title, content, filtro, isEditMode } = props;
  const { colaboradores } = content;
  const items = colaboradores
    ? colaboradores.filter(function (item) {
        return item.tipo?.token === filtro;
      })
    : [];
  return (
    <div className={`block colaboradores ${className}`}>
      {isEditMode && !(items && items.length > 0) && <Header title={title} />}
      {items && items.length > 0 && (
        <>
          <Header title={title} />
          <Card.Group className={'colaboradores'}>
            {items.map(function (pessoa, i) {
              const img = pessoa.image?.scales?.tile;
              return (
                <Card key={i}>
                  <Container className={'icon'}>
                    {img ? (
                      <img
                        src={img.download}
                        alt={`Foto de ${pessoa.title}`}
                        className={'portrait listitem'}
                      />
                    ) : (
                      <Icon name={personSVG} size="64px" />
                    )}
                  </Container>
                  <Card.Content>
                    <Card.Header>
                      <UniversalLink href={pessoa['@id']}>
                        {pessoa.title}
                      </UniversalLink>
                    </Card.Header>
                    <Card.Meta>{pessoa.cargo}</Card.Meta>
                  </Card.Content>
                </Card>
              );
            })}
          </Card.Group>
        </>
      )}
    </div>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ColaboradoresView.propTypes = {
  title: PropTypes.string,
};
ColaboradoresView.defaultProps = {
  title: 'Servidores',
  filtro: 'funcionario',
};

export default ColaboradoresView;
