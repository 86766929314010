import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers/Extensions';

import AreasBlockView from './View';

const AreasBlockEdit = (props) => {
  return (
    <>
      <AreasBlockView {...props} isEditMode />
    </>
  );
};

export default withBlockExtensions(AreasBlockEdit);
