import React from 'react';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import DataTable from 'react-data-table-component';
import ExportRow from '../ExportRow/ExportRow';
import { trimText } from '../../helpers/trim';

const columns = [
  {
    id: 'ano',
    name: 'Ano',
    selector: (row) => (row.contratacao_ano ? row.contratacao_ano : row.ano),
    format: (row, index) => (
      <span className={`processo ano`}>
        {row.contratacao_ano ? row.contratacao_ano : row.ano}
      </span>
    ),
    sortable: true,
  },
  {
    id: 'title',
    name: 'Contrato',
    selector: (row) => row.title,
    format: (row, index) => (
      <span className={`processo link`}>
        <UniversalLink item={row}>{row.title}</UniversalLink>
      </span>
    ),
  },
  {
    id: 'description',
    name: 'Sumário',
    selector: (row) => row.description,
    format: (row, index) => (
      <span className={`processo sumario text wrap`}>
        {row.description ? trimText(row.description) : '-'}
      </span>
    ),
  },
];

const Documentos = ({ url, params, documentos, allowExport }) => {
  return (
    <>
      <DataTable
        className={'documentos-items'}
        columns={columns}
        data={documentos}
        dense
        striped
      />
      {allowExport && (
        <ExportRow
          url={url}
          params={params}
          endpoint={'@export-licitacao/documentos'}
        />
      )}
    </>
  );
};

export default Documentos;
