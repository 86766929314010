import { Container } from '@plone/components';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import backSVG from '@plone/volto/icons/back.svg';
import aheadSVG from '@plone/volto/icons/ahead.svg';

const NextPrevious = (props) => {
  const { content, allowedTypes } = props;
  let { next_item, previous_item } = content;
  next_item = allowedTypes.includes(next_item?.['@type']) ? next_item : null;
  previous_item = allowedTypes.includes(previous_item?.['@type'])
    ? previous_item
    : null;

  return (
    <Container id="next-previous" className="ui nextPrevious">
      {previous_item && (
        <UniversalLink
          href={previous_item['@id']}
          className={'ui npItem previousItem'}
        >
          <Icon name={backSVG} size="24px" className={'npIcon'} />{' '}
          {previous_item.title}
        </UniversalLink>
      )}
      {next_item && (
        <UniversalLink href={next_item['@id']} className={'ui npItem nextItem'}>
          <Icon name={aheadSVG} size="24px" className={'npIcon'} />{' '}
          {next_item.title}
        </UniversalLink>
      )}
    </Container>
  );
};

export default NextPrevious;
