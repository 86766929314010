import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers/Extensions';
import VereadorView from './DefaultView';

const VereadorBlockView = (props) => {
  const { data, location, content, isEditMode, className } = props;
  return (
    <VereadorView
      {...data}
      isEditMode={isEditMode}
      className={className}
      location={location}
      content={content}
    />
  );
};

export default withBlockExtensions(VereadorBlockView);
