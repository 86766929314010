import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import { CEPWidgetDisplay } from '../../Widgets/CEPWidget';
import { TelefoneWidgetDisplay } from '../../Widgets/TelefoneWidget';
import emailSvg from '../../../icons/envelope-solid.svg';
import phoneSvg from '../../../icons/phone-solid.svg';
import locationSvg from '../../../icons/location-dot-solid.svg';

const LocalizacaoView = (props) => {
  const { className, content } = props;
  const { endereco, cidade, estado, cep, telefone, fax, email } = content;
  return (
    <div className={`block localizacao ${className}`}>
      <div className={'localizacaoWrapper'}>
        <div className={'colunas'}>
          <div className={'coluna'}>
            {endereco && (
              <div className="info endereco">
                <Icon name={locationSvg} size={'16px'} />{' '}
                <span>
                  {endereco} - <CEPWidgetDisplay value={cep} /> - {cidade} -{' '}
                  {estado.title}
                </span>
              </div>
            )}
          </div>
          <div className={'coluna'}>
            <div className="info telefone">
              {telefone && (
                <>
                  <Icon name={phoneSvg} size={'16px'} />{' '}
                  <span>
                    <TelefoneWidgetDisplay value={telefone} />
                  </span>
                </>
              )}
            </div>
            <div className="info fax">
              {fax && (
                <>
                  <Icon name={phoneSvg} size={'16px'} />{' '}
                  <span>
                    <TelefoneWidgetDisplay value={fax} />
                  </span>
                </>
              )}
            </div>
            <div className="info email">
              {email && (
                <>
                  <Icon name={emailSvg} size={'16px'} />{' '}
                  <a
                    href={`mailto:${email}`}
                    title="email"
                    className={'info email'}
                  >
                    {email}
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
LocalizacaoView.propTypes = {
  Localizacao: PropTypes.string,
};

export default LocalizacaoView;
