/**
 * Colaborador view component.
 * @module components/Views/ColaboradorView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@plone/components';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { TabPane, Tab } from 'semantic-ui-react';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';
import { CPFWidgetDisplay } from '../Widgets/CPFWidget';
import { TelefoneWidgetDisplay } from '../Widgets/TelefoneWidget';
import LocalDate from '../LocalDate/LocalDate';
import Estado from '../Estado/Estado';

const DadosPessoais = ({ area_info, cpf, id }) => {
  return (
    <Table className={'details'}>
      <TableBody>
        <TableRow>
          <TableCell className={'label'}>Matrícula</TableCell>
          <TableCell className={'value'}>{id}</TableCell>
        </TableRow>
        {area_info && (
          <TableRow>
            <TableCell className={'label'}>Unidade Administrativa</TableCell>
            <TableCell className={'value'}>
              <UniversalLink item={area_info}>{area_info.title}</UniversalLink>
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell className={'label'}>CPF</TableCell>
          <TableCell className={'value'}>
            <CPFWidgetDisplay value={cpf} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
const DadosContato = ({ carga_horaria, email, telefone }) => {
  return (
    <Table className={'details'}>
      <TableBody>
        {carga_horaria && (
          <TableRow>
            <TableCell className={'label'}>Horário de trabalho</TableCell>
            <TableCell className={'value'}>{carga_horaria}</TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell className={'label'}>E-mail</TableCell>
          <TableCell className={'value'}>{email ? email : '-'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Telefone</TableCell>
          <TableCell className={'value'}>
            {telefone ? <TelefoneWidgetDisplay value={telefone} /> : '-'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
const DadosContrato = ({ data_inicio, data_termino }) => {
  return (
    <Table className={'details'}>
      <TableBody>
        <TableRow>
          <TableCell className={'label'}>Início</TableCell>
          <TableCell className={'value'}>
            {data_inicio ? <LocalDate date={data_inicio} /> : '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Término</TableCell>
          <TableCell className={'value'}>
            {data_termino ? <LocalDate date={data_termino} /> : '-'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const ColaboradorView = (props) => {
  const { content } = props;
  const {
    id,
    cargo,
    title,
    area_info,
    carga_horaria,
    email,
    telefone,
    cpf,
    image,
    data_inicio,
    data_termino,
    review_state,
  } = content;
  const img = image?.scales?.thumb;
  const panes = [
    {
      menuItem: 'Informações',
      render: () => (
        <TabPane>
          <DadosPessoais cpf={cpf} area_info={area_info} id={id} />
        </TabPane>
      ),
    },
  ];
  if (email || telefone) {
    panes.push({
      menuItem: 'Contato',
      render: () => (
        <TabPane>
          <DadosContato
            email={email}
            telefone={telefone}
            carga_horaria={carga_horaria}
          />
        </TabPane>
      ),
    });
  }
  if (data_inicio || data_termino) {
    panes.push({
      menuItem: 'Exercício',
      render: () => (
        <TabPane>
          <DadosContrato
            data_inicio={data_inicio}
            data_termino={data_termino}
          />
        </TabPane>
      ),
    });
  }
  return (
    <Container id="page-document" className="view-wrapper colaborador-view">
      <Container className={'colaboradorHeader'}>
        <Container className={'colaboradorDados'}>
          <div className={'nome'}>
            <h1 className="documentFirstHeading">{title}</h1>
            <Estado review_state={review_state} />
          </div>
          {cargo && <p className="documentDescription">{cargo}</p>}
        </Container>
        {img && (
          <Container className={'portrait'}>
            <img
              src={img.download}
              alt={`Foto de ${title}`}
              className={'portrait item'}
            />
          </Container>
        )}
      </Container>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ColaboradorView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default ColaboradorView;
